import * as Dialog from '@radix-ui/react-dialog';
import { Button, IconButton } from '../../../../../../shared/ui/button';
import clsx from 'clsx';
import { ClearIcon } from '@dop-ui/icons/react/dop/24';
import { useTranslation } from '../../../../../../shared/lib/i18n/client/use-translation';

interface Props {
  onReset: () => void;
}

export function ManageCancelDialog({ onReset }: Props) {
  const { t } = useTranslation('component');

  return (
    <Dialog.Root>
      <Dialog.Trigger asChild>
        <Button onClick={() => {}} size="md" shape="rect" variant="solid">
          {t('dashboard.cancel')}
        </Button>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="DialogOverlay !z-10" />
        <Dialog.Content className="DialogContent !z-20">
          <div className="flex flex-row justify-between items-center">
            <Dialog.Title className={clsx('DialogTitle')}>
              {t('dashboard.edit.cancel')}
            </Dialog.Title>
            <Dialog.Close asChild>
              <IconButton size="large" icon={ClearIcon} />
            </Dialog.Close>
          </div>

          <Dialog.Description className="DialogDescription">
            {t('manage.dashboard.edit.cancel.description')}
          </Dialog.Description>
          <div className="flex flex-row justify-end">
            <Dialog.Close asChild>
              <Button size="md" shape="rect" onClick={onReset}>
                {t('dashboard.edit.cancel')}
              </Button>
            </Dialog.Close>
            <Dialog.Close asChild>
              <Button size="md" shape="rect">
                {t('dashboard.close')}
              </Button>
            </Dialog.Close>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
