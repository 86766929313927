import { formatDate } from '../../../../../../shared/lib/date';
import { BoardInfo } from '../../types';
import styles from './styles.module.css';

interface Props {
  data: BoardInfo;
  postUrl: string;
  appCode: string;
}

export function AlbumTemplate({ data, postUrl, appCode }: Props) {
  return (
    <li className="px-2">
      <div className={styles.BoardAlbum}>
        {data.repImage?.path && (
          <a href={postUrl}>
            <div className={styles.OverlayTxt}>
              <span className={styles.BoardTitle}>
                {data.title ? (
                  <span>
                    {data.title}
                    {data.commentsCount > 0 && `[${data.commentsCount}]`}
                  </span>
                ) : (
                  <span>
                    {data.summary}
                    {data.commentsCount > 0 && `[${data.commentsCount}]`}
                  </span>
                )}
              </span>

              <span className={styles.BoardAlbumDate}>
                {formatDate({
                  date: data.createdAt,
                  format: 'YYYY-MM-DD hh:mm',
                })}
              </span>
              <span className={styles.BoardAlbumName}>
                {`${data.writer?.name} ${data.writer?.positionName}`}
              </span>
            </div>
            <div
              className={styles.BoardAlbumImg}
              style={{
                backgroundImage: `url(/api/thumb/attach/mid/${appCode}/${data.repImage.path})`,
                backgroundColor: 'black',
              }}
            ></div>
          </a>
        )}
      </div>
    </li>
  );
}

export default AlbumTemplate;
