import { ChangeEvent, PropsWithChildren } from 'react';
import { useTranslation } from '../../../../shared/lib/i18n/client/use-translation';
import { useActionsContext, useValuesContext } from './context';
import { Button } from '../../../../shared/ui/button/dop-button';
import { CheckIcon } from '@dop-ui/icons/react/dop/16';
import { CloseIcon } from '@dop-ui/icons/react/dop/24';
import { GADGET_REFRESH_RATES } from '../constants';
import { ConfigField, GadgetCheckBox, Select } from './components';
import styles from './styles.module.css';

export interface Props {
  /** @property 가젯 새로고침 옵션 여부 (기본 - true)*/
  isRefreshable?: boolean;
  /** @property 가젯 테두리 강조 옵션 여부 (기본 - true)*/
  isHighlightable?: boolean;
  /** @property 가젯 에러 메시지 */
  errorMessage?: string;
}

export function Config({
  isRefreshable = true,
  isHighlightable = true,
  errorMessage,
  children,
}: PropsWithChildren<Props>) {
  const { uuid, config, isEditing, title, editable } = useValuesContext();
  const { setIsEditing, onChangeConfig, onSave, onCancel } =
    useActionsContext();

  const { t } = useTranslation('component');

  const handleOnCancel = () => {
    setIsEditing(false);
    onCancel();
  };

  const handleOnSave = () => {
    if (onSave) setIsEditing(!onSave(config));
    else setIsEditing(false);
  };

  const handleOnChangeRefresh = (e: ChangeEvent<HTMLSelectElement>) => {
    onChangeConfig({
      refresh: e.target.value,
    });
  };
  const refreshOption = isRefreshable && (
    <li>
      <ConfigField title={t('gadget.refresh')} />
      <Select defaultValue={config.refresh} onChange={handleOnChangeRefresh}>
        {GADGET_REFRESH_RATES.map((rate) => (
          <option key={rate.value} value={rate.value}>
            {t(rate.title)}
          </option>
        ))}
      </Select>
    </li>
  );

  const highlightOption = isHighlightable && (
    <li>
      <ConfigField title={t('gadget.outline')} />
      <GadgetCheckBox
        id={`gadget-highlight-option-${uuid}`}
        className="gadget_checkbox"
        defaultChecked={config.highlight ?? false}
        onChange={(checked) =>
          onChangeConfig({
            highlight: !!checked,
          })
        }
        label={t('gadget.stress')}
      />
    </li>
  );

  const errorBlock = errorMessage && (
    <p className="desc">
      <span className="txt_caution error-msg-wrapper">{errorMessage}</span>
    </p>
  );

  return (
    isEditing &&
    editable && (
      <div className={styles.GadgetEdit}>
        <div className={styles.GadgetHeader}>
          <p className={styles.GadgetTitle}>{title}</p>
          <Button
            className="bg-transparent hover:bg-[#EEF1F7]"
            shape="round"
            size="sm"
            onClick={() => handleOnSave()}
          >
            <CheckIcon size={16} />
            {t('gadget.confirm')}
          </Button>
          <Button
            className="bg-transparent hover:bg-[#EEF1F7]"
            shape="round"
            size="sm"
            onClick={() => handleOnCancel()}
          >
            <CloseIcon size={16} />
            {t('gadget.cancel')}
          </Button>
        </div>
        {errorBlock}
        <form className={styles.EditForm}>
          {refreshOption}
          {children}
          {highlightOption}
        </form>
        <div className={styles.Divider} />
      </div>
    )
  );
}

export default Config;
