import { BoardInfo } from '../../types';
import styles from './styles.module.css';
import * as ToolTip from '../../../../../../shared/ui/tooltip';
import { Avatar } from '../../../../../../shared/ui/avatar';
import { clsx } from 'clsx';
import { formatDate } from '../../../../../../shared/lib/date';

interface Props {
  data: BoardInfo;
  postUrl: string;
  boardUrl: string;
}

export function NormalTemplate({ data, postUrl, boardUrl }: Props) {
  return (
    <li className={styles.BoardItem}>
      <>
        <ToolTip.Root>
          <ToolTip.Trigger asChild>
            <p className={styles.BoardTitle}>
              {data.title ? (
                <a
                  className={clsx({
                    [styles.BoardStickable as string]: data.stickable,
                  })}
                  href={postUrl}
                >
                  {data.title}
                  {data.commentsCount > 0 && `[${data.commentsCount}]`}
                </a>
              ) : (
                <a
                  className={clsx({
                    [styles.BoardStickable as string]: data.stickable,
                  })}
                  href={boardUrl}
                >
                  {data.summary}
                  {data.commentsCount > 0 && `[${data.commentsCount}]`}
                </a>
              )}
            </p>
          </ToolTip.Trigger>
          <ToolTip.Portal>
            <ToolTip.Content>
              <div>
                {data.title && <p>{data.title}</p>}
                <p>{data.summary}</p>
              </div>
            </ToolTip.Content>
          </ToolTip.Portal>
        </ToolTip.Root>
        <span className={styles.BoardInfo}>
          <Avatar
            size={14}
            alt={data.writer?.name}
            src={data.writer?.photoPath}
          />
          <span>{`${data.writer?.name} ${data.writer?.positionName}`}</span>
          <span>{` · ${formatDate({
            date: data.createdAt,
            format: 'YYYY-MM-DD hh:mm',
          })}`}</span>
          <span>
            <span>{` · `}</span>
            <a href={boardUrl}>{data.boardName}</a>
          </span>
        </span>
      </>
    </li>
  );
}

export default NormalTemplate;
