import { useTranslation } from '../../../../../shared/lib/i18n/client/use-translation';

interface Props {
  gadgetName?: string;
  dashboardName?: string;
}
export function DeleteConfirmDialog({ dashboardName, gadgetName }: Props) {
  const { t } = useTranslation('component');

  return (
    <div className="size-full px-[24px]">
      <p className="text-[#1A1A1A] text-[20px] font-[500]">
        {t('gadget.delete.confirm.title')}
      </p>
      <p className="mt-[24px] text-[#1A1A1A] text-[16px] font-[400] whitespace-pre-wrap">
        {t('gadget.delete.confirm.message', { dashboardName, gadgetName })}
      </p>
    </div>
  );
}
export default DeleteConfirmDialog;
