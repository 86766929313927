import { PropsWithChildren } from 'react';
import { useActionsContext, useValuesContext } from './context';
import { IconButton } from '../../../../shared/ui/button';
import { DeleteIcon, SettingsIcon } from '@dop-ui/icons/react/dop/24';
import { clsx } from 'clsx';
import { ErrorBoundary } from '../../../../shared/ui/error-boundary';
import styles from './styles.module.css';
import { GadgetEmpty } from './components';

export interface Props {
  /** @property 가젯 제목 표시 여부 설정 (기본 true) */
  showTitle?: boolean;

  /** @property 컨텐츠 영역이 다른 가젯들과 다를 때 className 커스텀 필요 */
  className?: string;

  /** @property 테두리 강조 시 배경색, 아이콘 등 변경될 때 ex) 프로필카드 */
  darkMode?: boolean;
}

export function Contents({
  showTitle = true,
  className,
  darkMode,
  children,
}: PropsWithChildren<Props>) {
  const { title, editable, isEditing, config } = useValuesContext();
  const { setIsEditing, onDelete } = useActionsContext();

  const ErrorFallback = ({ error }: { error: { message: string } }) => {
    return <GadgetEmpty message={error.message} />;
  };

  return (
    <>
      {showTitle && (
        <div className={styles.GadgetHeader}>
          <p className={styles.GadgetTitle}>{title}</p>
          {editable && !isEditing && (
            <>
              <IconButton
                className={styles.HeaderSettingButton}
                icon={SettingsIcon}
                size="sm"
                onClick={() => setIsEditing(true)}
              />
              <IconButton
                className={styles.HeaderSettingButton}
                size="sm"
                icon={DeleteIcon}
                onClick={onDelete}
              />
            </>
          )}
        </div>
      )}
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <div
          className={clsx(
            styles.GadgetContents,
            className,
            {
              [styles.NoHeaderContents as string]: !showTitle,
            },
            {
              [styles.DarkmodeGadgetContents as string]:
                darkMode && config.highlight,
            },
          )}
        >
          {/* title이 없을 때 IconButton 위치가 top-[35px] */}
          {!showTitle && editable && !isEditing && (
            <div className={styles.NoHeaderSetting}>
              <IconButton
                className={clsx(styles.HeaderSettingButton, {
                  [styles.DarkmodeHeaderSettingButton as string]:
                    darkMode && config.highlight,
                })}
                icon={SettingsIcon}
                size="sm"
                onClick={() => setIsEditing(true)}
              />
              <IconButton
                className={clsx(styles.HeaderSettingButton, {
                  [styles.DarkmodeHeaderSettingButton as string]:
                    darkMode && config.highlight,
                })}
                size="sm"
                icon={DeleteIcon}
                onClick={onDelete}
              />
            </div>
          )}
          {children}
        </div>
      </ErrorBoundary>
    </>
  );
}

export default Contents;
