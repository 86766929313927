import React from 'react';
import { ManageGadgetBoard } from './manage-gadget-board';

interface Props {
  isEditMode: boolean;
}

export function ManageGadgetContent({ isEditMode }: Props) {
  return (
    <React.Suspense fallback={<div>Loading...</div>}>
      <ManageGadgetBoard isEditMode={isEditMode} />
    </React.Suspense>
  );
}
