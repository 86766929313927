import { PropsWithChildren, SelectHTMLAttributes } from 'react';
import { clsx } from 'clsx';
import styles from '../styles.module.css';
interface Props extends SelectHTMLAttributes<HTMLSelectElement> {
  className?: string;
}

export function GadgetSelect({
  children,
  className,
  ...props
}: PropsWithChildren<Props>) {
  return (
    <select className={clsx(styles.Select, className)} {...props}>
      {children}
    </select>
  );
}

export default GadgetSelect;
