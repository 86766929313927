import { GadgetApiParams } from '../../../types';
import Gadget from '../../content/gadget';

interface Props {
  isEditMode: boolean;
  gadget: GadgetApiParams;
  seq: number;
  dashboardId: number;
}

export default function ChatGadgetContainer({ isEditMode, gadget }: Props) {
  return <Gadget spec={gadget} isEditMode={isEditMode} />;
}
