import { NoData } from '../../../../../shared/ui/svg/no-data';
import styles from '../styles.module.css';
interface Props {
  message: string;
}
export function GadgetEmpty({ message }: Props) {
  return (
    <div className={styles.EmptyContainer}>
      <NoData />
      <p className={styles.EmptyMessage}>{message}</p>
    </div>
  );
}

export default GadgetEmpty;
