import { useEffect, useRef, useState } from 'react';
import styles from './carousel.module.css';
import {
  NextIndicatorIcon,
  PreviousIndicatorIcon,
} from '@dop-ui/icons/react/dop/16';
import { clsx } from 'clsx';
import { IconButton } from '../../../../../../shared/ui/button';

interface Props<T> {
  data: T[];
  itemWidth: number;
  gapSize?: number;
  children: (item: T) => React.ReactNode;
}

export function Carousel<T>({ data, itemWidth, gapSize, children }: Props<T>) {
  const DEFAULT_CARD_WIDTH = itemWidth;
  const GAP = gapSize ?? 0;

  const [curIndex, setCurIndex] = useState(0);
  const [renderingItemCount, setRenderingItemCount] = useState(1);
  const [cardItemWrapperwidth, setCardItemWrapperWidth] = useState(0);
  const carouselRef = useRef<HTMLUListElement>(null);
  const cardItemWrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.target === cardItemWrapperRef.current) {
          setCardItemWrapperWidth(entry.contentRect.width);
        }
      }
    });

    if (cardItemWrapperRef.current) {
      observer.observe(cardItemWrapperRef.current);
    }

    return () => {
      if (cardItemWrapperRef.current) {
        observer.unobserve(cardItemWrapperRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (carouselRef.current !== null) {
      if (curIndex === 0) {
        carouselRef.current.style.transform = `translateX(-${0}px)`;
      } else {
        carouselRef.current.style.transform = `translateX(-${curIndex * (DEFAULT_CARD_WIDTH + GAP)}px)`;
      }
    }
  }, [cardItemWrapperwidth, curIndex]);

  useEffect(() => {
    if (cardItemWrapperRef.current !== null) {
      setRenderingItemCount(
        Math.floor(
          (cardItemWrapperRef.current.offsetWidth -
            GAP * (renderingItemCount - 1)) /
            DEFAULT_CARD_WIDTH,
        ),
      );
    }
  }, [cardItemWrapperwidth]);

  const handleSwipe = (direction: number) => {
    setCurIndex((prevIndex) => prevIndex + direction * renderingItemCount);
    if (carouselRef.current !== null) {
      carouselRef.current.style.transition = 'all 0.5s ease-in-out';
    }
  };

  return (
    <div>
      <div ref={cardItemWrapperRef}>
        {data && (
          <div className={styles.container}>
            <div className={styles.carouselWrapper}>
              <ul className={styles.carousel} ref={carouselRef}>
                {data.map((reportInfo, idx) => {
                  return (
                    <li
                      key={idx}
                      className={styles.carouselItem}
                      style={{
                        visibility:
                          idx < curIndex || idx >= curIndex + renderingItemCount
                            ? 'hidden'
                            : 'visible',
                        width: `${DEFAULT_CARD_WIDTH}px`,
                      }}
                    >
                      {children(reportInfo)}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        )}
      </div>
      <div className={clsx(styles.Paginator)}>
        <IconButton
          size="xs"
          onClick={() => handleSwipe(-1)}
          disabled={curIndex < 1}
          icon={PreviousIndicatorIcon}
        />

        <IconButton
          size="xs"
          disabled={curIndex + renderingItemCount >= data.length}
          onClick={() => handleSwipe(1)}
          icon={NextIndicatorIcon}
        />
      </div>
    </div>
  );
}

export default Carousel;
