export function ManageGadgetDropArea() {
  return (
    <div
      style={{
        border: '1px dashed rgb(170, 170, 170)',
        minHeight: '100px',
      }}
    />
  );
}
