import React from 'react';
import { useDashboardContext } from '../../../context';
import { ChatGadgetBoard } from './chat-gadget-board';

interface Props {
  width: number;
}

export function ChatDashboardContent({ width }: Props) {
  const {
    state: { currentDashboard },
  } = useDashboardContext();
  if (!currentDashboard) return null;

  return (
    <React.Suspense fallback={<div>Loading...</div>}>
      <ChatGadgetBoard width={width} />
    </React.Suspense>
  );
}
