import * as Dialog from '@radix-ui/react-dialog';
import { useTranslation } from '../../../../../../shared/lib/i18n/client/use-translation';
import { Button, IconButton } from '../../../../../../shared/ui/button';
import clsx from 'clsx';
import { ClearIcon } from '@dop-ui/icons/react/dop/24';

export interface DeleteDialogProps {
  gadgetName: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onDelete: () => void;
}

export function ManageDeleteDialog({
  gadgetName,
  open,
  setOpen,
  onDelete,
}: DeleteDialogProps) {
  const { t } = useTranslation('component');
  const message = t('manage.dashboard.gadget.delete.description');
  const gadgetDeleteDescription = message.replace('(gadgetName)', gadgetName);
  return (
    <Dialog.Root open={open}>
      <Dialog.Portal>
        <Dialog.Overlay className="DialogOverlay !z-10" />
        <Dialog.Content className="DialogContent !z-20">
          <div className="flex flex-row justify-between items-center">
            <Dialog.Title className={clsx('DialogTitle')}>
              {t('dashboard.gadget.delete')}
            </Dialog.Title>
            <Dialog.Close onClick={() => setOpen(false)}>
              <IconButton size="large" icon={ClearIcon} />
            </Dialog.Close>
          </div>

          <Dialog.Description className="DialogDescription">
            {gadgetDeleteDescription}
          </Dialog.Description>
          <div className="flex flex-row justify-end">
            <Dialog.Close onClick={() => setOpen(false)}>
              <Button size="md" shape="rect">
                {t('dashboard.cancel')}
              </Button>
            </Dialog.Close>
            <Dialog.Close asChild>
              <Button size="md" shape="rect" onClick={onDelete}>
                {t('dashboard.delete')}
              </Button>
            </Dialog.Close>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
