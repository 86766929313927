import { PropsWithChildren } from 'react';
import { Provider } from './context';
import { Wrapper } from './wrapper';
import type { GadgetConfig } from './types';

export interface Props {
  /** @property 가젯 고유 id */
  uuid: string;
  /** @property 지역화 언어 (기본값 'ko') */
  locale?: string;
  /** @property 가젯 이름 */
  name: string;
  /** @property 가젯 제목 */
  title?: string;
  /** @property 가젯 편집 가능 상태 (대시보드 편집 관련) */
  editable?: boolean;
  /** @property 가젯 설정 */
  config?: GadgetConfig;
  /** @property 대시보드 이름 */
  dashboardName?: string;
  /**
   * @function 가젯 설정 저장 버튼 클릭 이벤트 핸들러
   * @return 저장 성공여부를 알려주는 boolean값
   */
  onSave?: (config: GadgetConfig) => boolean;
  /** @function 가젯 설정 취소 버튼 클릭 이벤트 핸들러 */
  onCancel?: () => void;
  /** @function 가젯 삭제버튼 클릭 액션 */
  onDelete?: () => void;
}

export function Root({
  uuid,
  locale = 'ko',
  name,
  title,
  editable,
  config,
  children,
  onSave,
  onCancel,
  onDelete,
}: PropsWithChildren<Props>) {
  const gadgetTitle = title ?? name;
  return (
    <Provider
      uuid={uuid}
      locale={locale}
      title={gadgetTitle}
      editable={editable}
      config={config}
      onSave={onSave}
      onCancel={onCancel}
      onDelete={onDelete}
    >
      <Wrapper>{children}</Wrapper>
    </Provider>
  );
}

export default Root;
