import { GadgetApiParams } from '../../../types';
import Gadget from '../../content/gadget';
import { ManageDeleteDialog } from '../header/control/manage-delete-dialog';

interface Props {
  isEditMode: boolean;
  gadget: GadgetApiParams;
  seq: number;
  dashboardId: number;
}

export function ManageGadgetContainer({ isEditMode, gadget }: Props) {
  return (
    <Gadget
      spec={gadget}
      isEditMode={isEditMode}
      DeleteDialog={ManageDeleteDialog}
    />
  );
}
