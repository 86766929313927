import { Button } from '../../../../../../shared/ui/button';
import HeaderContainer from '../../../header/container';
import { useTranslation } from '../../../../../../shared/lib/i18n/client';
import {
  useDashboardActionsContext,
  useDashboardValuesContext,
} from '../../../../context';
import * as apis from '../../../../apis';
import { ChatCancelDialog } from './chat-cancel-dialog';

export default function ChatControlToolbar() {
  const { t } = useTranslation();
  const { currentDashboard, gadgets, originGadgets } =
    useDashboardValuesContext();
  const {
    addDashboards,
    setCurrentDashboard,
    setOriginGadgets,
    setPrevDashboard,
    editDashboard,
    setGadgets,
    setEditMode,
  } = useDashboardActionsContext();

  const handleResetClick = () => {
    if (!currentDashboard) return;

    setGadgets([...originGadgets]);
    setEditMode(false);
  };

  const handleSaveClick = async () => {
    if (!currentDashboard) return;

    try {
      if (currentDashboard.dashboardId === -1) {
        await createDashboard();
      } else {
        await updateDashboard();
      }
    } catch (e) {
      console.error(e);
    }
  };

  const updateDashboard = async () => {
    if (!currentDashboard) return;

    const resp = await apis.updateDashboard({
      dashboardId: currentDashboard.dashboardId,
      name: currentDashboard.name,
      layout: currentDashboard.layout,
      gadgetList: gadgets,
    });
    const updatedDashboard = { ...currentDashboard, ...resp };
    editDashboard(updatedDashboard);
    clear();
    setEditMode(false);
  };

  const createDashboard = async () => {
    if (!currentDashboard) return;

    const resp = await apis.createDashboard(
      {
        name: currentDashboard.name,
        layout: currentDashboard.layout,
        gadgetList: gadgets,
      },
      'EMPLOYEE',
    );
    const createdDashboard = {
      ...currentDashboard,
      ...resp,
      // 임시 로직. Response 값에 activated가 false로 내려와서 임시로 추가함
      ...{ activated: true },
    };
    addDashboards(createdDashboard);
    setCurrentDashboard(createdDashboard);
    clear();
    setEditMode(false);
  };

  const clear = () => {
    setOriginGadgets([]);
    setPrevDashboard(null);
  };

  return (
    <HeaderContainer>
      <div className="flex flex-row justify-between align-middle w-full">
        <h1 className="text-2xl">{t('chat.home.today.title')}</h1>
        <div className="flex flex-row gap-2">
          <ChatCancelDialog onReset={handleResetClick} />
          <Button
            onClick={handleSaveClick}
            size="md"
            shape="rect"
            variant="solid"
          >
            {t('chat.save')}
          </Button>
        </div>
      </div>
    </HeaderContainer>
  );
}
