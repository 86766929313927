import * as DnDList from '../../../../shared/ui/dnd/list';
import { generateUUID } from '../../apis';
import { useDashboardContext } from '../../context';
import { DashboardType, GadgetSpecProps } from '../../types';
import { ManageGadgetContent } from './content/manage-gadget-content';
import { getGadgetDefaultData } from './header/control/manage-gadget-spec-item';
import { ManageHeader } from './header/manage-header';

interface Props {
  isEditMode: boolean;
  setEditMode?: React.Dispatch<React.SetStateAction<boolean>>;
  dashboardType?: DashboardType;
}

export function ManageDashboardContainer({ isEditMode, setEditMode }: Props) {
  const context = useDashboardContext();
  const { gadgets } = context.state;
  const { reorderGadgets } = context.actions;

  const handleOnDrop = ({
    sourceData,
    targetData,
    edge,
  }: DnDList.DropParams) => {
    let sourceGadget = gadgets.find(
      (gadget) => gadget.uuid === sourceData['uuid'],
    );
    const targetGadget = gadgets.find(
      (gadget) => gadget.uuid === targetData['uuid'],
    );

    // sourceGadget이 thumbnail인 경우
    const sourceUUID = sourceData['uuid'] as string;
    if (sourceUUID.includes('gadget-thumbnail')) {
      const spec = sourceData['data'] as GadgetSpecProps;
      const gadget = {
        ...getGadgetDefaultData(),
        ...spec,
        uuid: generateUUID(),
      };
      sourceGadget = gadget;
    }

    // 빈 column에 drop할 경우
    const targetUUID = targetData['uuid'] as string;
    if (isDropEmptyColumn(targetUUID) && sourceGadget) {
      const emptyColumn = targetUUID.split('-')[3];
      if (!emptyColumn) return;
      reorderGadgets(sourceGadget, parseInt(emptyColumn), 0);
      return;
    }

    if (!sourceGadget || !targetGadget) return;

    if (edge === 'top') {
      reorderGadgets(sourceGadget, targetGadget.region, targetGadget.sequence);
    } else if (edge === 'bottom') {
      reorderGadgets(
        sourceGadget,
        targetGadget.region,
        targetGadget.sequence + 1,
      );
    }
  };

  const isDropEmptyColumn = (targetUUID: string) => {
    if (!targetUUID) return false;
    if (!targetUUID.includes('gadget-drop-area')) return false;
    return true;
  };

  return (
    <DnDList.Provider idKey="uuid" name="manage_gadget_board">
      <DnDList.Container
        list={gadgets}
        setList={() => {}}
        onDrop={handleOnDrop}
      >
        <ManageHeader isEditMode={isEditMode} setEditMode={setEditMode} />
        <ManageGadgetContent isEditMode={isEditMode} />
      </DnDList.Container>
    </DnDList.Provider>
  );
}
