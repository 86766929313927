import { refreshRate } from './gadget/types';
export const GADGET_REFRESH_RATES = [
  {
    value: refreshRate.none,
    title: 'gadget.refreshRate.none',
  },
  {
    value: refreshRate.oneMin,
    title: 'gadget.refreshRate.oneMin',
  },
  {
    value: refreshRate.fiveMin,
    title: 'gadget.refreshRate.fiveMin',
  },
  {
    value: refreshRate.tenMin,
    title: 'gadget.refreshRate.tenMin',
  },
  {
    value: refreshRate.fifteenMin,
    title: 'gadget.refreshRate.fifteenMin',
  },
  {
    value: refreshRate.thirtyMin,
    title: 'gadget.refreshRate.thirtyMin',
  },
  {
    value: refreshRate.oneHour,
    title: 'gadget.refreshRate.oneHour',
  },
  {
    value: refreshRate.twoHour,
    title: 'gadget.refreshRate.twoHour',
  },
];
