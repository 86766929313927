import { useState } from 'react';

import { useDebounce } from '../../../../../ui/lib/hooks/useDebounce';

import { GadgetDropArea } from './gadget-drop-area';

import { GadgetApiParams } from '../../types';
import { useDashboardActionsContext } from '../../context';
import Gadget from './gadget';

export interface Props {
  isEditMode: boolean;
  gadget: GadgetApiParams;
  column: number;
  seq: number;
  dashboardId: number;
  dashboardName: string;
}

export default function GadgetContainer({
  isEditMode,
  gadget,
  column,
  seq,
  dashboardId,
  dashboardName,
}: Props) {
  const context = useDashboardActionsContext();
  const { editGadget, isPreventDrop } = context;

  const [isDragOver, setIsDragOver] = useState(false);
  const [isDragging, setIsDragging] = useState(false);

  const handleDragStart = (e: React.DragEvent) => {
    if (!isEditMode) return;
    if (!e.nativeEvent.dataTransfer) return;

    e.nativeEvent.dataTransfer.setData('text/plain', JSON.stringify(gadget));
    setIsDragging(true);
    editGadget({ ...gadget, isDragging: true });
  };

  const handleDragEnd = () => {
    setIsDragging(false);
  };

  const handleDragOver = useDebounce((e: React.DragEvent) => {
    e.preventDefault();
    if (isDragging) return;
    if (!e.nativeEvent.dataTransfer) return;

    e.nativeEvent.dataTransfer.dropEffect = 'move';
  }, 250);

  const handleDragEnter = (e: React.DragEvent) => {
    e.preventDefault();
    if (isDragging || isPreventDrop(gadget)) return;
    setIsDragOver(true);
  };

  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault();
    if (e.currentTarget.contains(e.relatedTarget as Node)) return;
    if (isDragging) return;
    setIsDragOver(false);
  };

  return (
    <div
      draggable
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDragEnter={handleDragEnter}
      onDragEnd={handleDragEnd}
    >
      {isEditMode && isDragOver && (
        <GadgetDropArea
          column={column}
          seq={seq}
          dashboardId={dashboardId}
          isDragOverParam={isDragOver}
          setIsDragOverParam={setIsDragOver}
        />
      )}
      <Gadget
        spec={gadget}
        isEditMode={isEditMode}
        dashboardName={dashboardName}
      ></Gadget>
    </div>
  );
}
