import * as Dialog from '@radix-ui/react-dialog';
import { useTranslation } from '../../../../../../shared/lib/i18n/client';
import { Button, IconButton } from '../../../../../../shared/ui/button';
import './styles.css';
import { ClearIcon } from '@dop-ui/icons/react/dop/24';
import clsx from 'clsx';

interface Props {
  onReset: () => void;
}

export function ChatCancelDialog({ onReset }: Props) {
  const { t } = useTranslation();
  return (
    <Dialog.Root>
      <Dialog.Trigger asChild>
        <Button onClick={() => {}} size="md" shape="rect" variant="solid">
          {t('chat.cancel')}
        </Button>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="DialogOverlay" />
        <Dialog.Content className="DialogContent">
          <div className="flex flex-row justify-between items-center">
            <Dialog.Title className={clsx('DialogTitle')}>
              {t('chat.home.today.setting.cancel')}
            </Dialog.Title>
            <Dialog.Close asChild>
              <IconButton size="large" icon={ClearIcon} />
            </Dialog.Close>
          </div>

          <Dialog.Description className="DialogDescription">
            {t('chat.home.today.setting.description')}
          </Dialog.Description>
          <div className="flex flex-row justify-end">
            <Dialog.Close asChild>
              <Button size="md" shape="rect" onClick={onReset}>
                {t('chat.setting.cancel')}
              </Button>
            </Dialog.Close>
            <Dialog.Close asChild>
              <Button size="md" shape="rect">
                {t('chat.close')}
              </Button>
            </Dialog.Close>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
