import { default as Root } from './root';
export type { Props as GadgetRootProps } from './root';

import { default as Config } from './config';
export type { Props as GadgetConfigProps } from './config';

import { default as Contents } from './contents';
export type { Props as GadgetContentsProps } from './contents';

import * as Context from './context';

export const GadgetPrimitives = {
  Root,
  Config,
  Contents,
  Context,
};

export type { GadgetStatus, GadgetConfig, GadgetProps } from './types';

export * as GadgetContexts from './context';
export * as GadgetUtils from '../../utils/locale';
export * as GadgetComponents from './components';
export * from './components';

export {
  ValuesContext as GadgetValuesContext,
  ActionsContext as GadgetActionsContext,
  useGenericContext as useGadgetContext,
  useGenericActionsContext as useGadgetActionsContext,
  useGenericValuesContext as useGadgetValuesContext,
} from './context';
