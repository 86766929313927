import { useDashboardValuesContext } from '../../../context';
import DashboardControl from '../../header/control';
import { ChatDashboardHeader } from './chat-dashboard-header';

export function ChatHeader() {
  const { isEditMode } = useDashboardValuesContext();
  return isEditMode ? (
    <DashboardControl dashboardType="MESSENGER" />
  ) : (
    <ChatDashboardHeader />
  );
}
