import { clsx } from 'clsx';
import { PropsWithChildren } from 'react';
import { useValuesContext } from './context';
import styles from './styles.module.css';

export function Wrapper({ children }: PropsWithChildren) {
  const { config } = useValuesContext();

  return (
    <div
      className={clsx(styles.Gadget, {
        [styles.Highlight as string]: config.highlight,
      })}
    >
      {children}
    </div>
  );
}

export default Wrapper;
