import * as React from 'react';
import { useTranslation } from '../../../../shared/lib/i18n/client';
import { GadgetColumn } from './gadget-column';
import { readGadgets } from '../../apis';
import { useDashboardContext } from '../../context';
import { Button } from '../../../../shared/ui/button';
import { CogIcon } from '@dop-ui/icons/react/dop/24';

export default function GadgetBoard() {
  const { t } = useTranslation('component');
  const context = useDashboardContext();
  const { gadgets, currentDashboard, isEditMode } = context.state;
  const { setEditMode, setOriginGadgets, setGadgets } = context.actions;

  React.useEffect(() => {
    const getGadgetData = () => {
      if (!currentDashboard) return;
      if (currentDashboard.dashboardId === -1) return;
      // 수정 모드일 때도 가젯을 읽어와야 하는가?
      // 수정 모드일 때 가젯을 읽어온다면, 수정 중인 내용이 날아갈 수 있다.
      // 수정 모드 진입 전에 다른 사용자가 대시보드를 수정했을 경우, 수정 중인 내용이 날아갈 수 있다.
      // 이는 양방향 동기화 기능이 필요하기 때문에, 현재로서는 완전히 해결할 수 없고,
      // 주로 개인 대시보드에서 수정이 이루어질 것이므로, 양방향 동기화에 대한 고려는 하지 않는다.
      // 따라서, 수정 중인 가젯 배치나 설정이 패치에 의해 날아가지 않도록 수정 모드일 때 가젯을 읽어오지 않는다(강봉수)
      if (isEditMode) return;

      readGadgets(currentDashboard.dashboardId)
        .then((gadgetsdData) => {
          setGadgets(gadgetsdData.gadgetList);
          setOriginGadgets(gadgetsdData.gadgetList);
        })
        .catch((e) => console.log(e));
    };

    getGadgetData();
  }, [currentDashboard, setOriginGadgets, setGadgets]);

  if (!currentDashboard) {
    return;
  }

  const getClassName = (layout: number) => {
    return layout === 4 ? '5_1' : `3_${layout}`;
  };

  const curDashboardLayout = currentDashboard.layout;
  const curDashboardId = currentDashboard.dashboardId;
  const curDashboardName = currentDashboard.name;

  return (
    <>
      <div
        className={`h-[calc(100%-48px)] !overflow-y-auto !pb-[80px] !bg-white go-dashboard go_dashboard_${getClassName(curDashboardLayout)}${isEditMode ? ' go-dashboard-editing' : ''}`}
      >
        {!gadgets.length && !isEditMode ? (
          <div className="flex flex-col justify-center items-center gap-10 h-full">
            <p className="text-[#c5c5c5] text-[16px]">
              {t('dashboard.gadget.empty.message')}
            </p>

            <Button
              onClick={() => setEditMode(!isEditMode)}
              size="md"
              variant="outline"
              shape="round"
              colorset="level1"
            >
              <CogIcon />
              <p>{t('dashboard.gadget.add')}</p>
            </Button>
          </div>
        ) : (
          <>
            <GadgetColumn
              column={1}
              section={1}
              dashboardId={curDashboardId}
              dashboardName={curDashboardName}
              isEditMode={isEditMode}
            ></GadgetColumn>
            <div className="gadget_layout_wrapper">
              <div className="gadget_layout_wrapper2">
                <div className="gadget_layout_wrapper3">
                  <GadgetColumn
                    column={2}
                    section={curDashboardLayout === 3 ? 2 : 1}
                    dashboardId={curDashboardId}
                    dashboardName={curDashboardName}
                    isEditMode={isEditMode}
                  ></GadgetColumn>
                </div>
                {curDashboardLayout === 4 && (
                  <div className="gadget_layout_wrapper4">
                    <GadgetColumn
                      column={4}
                      section={2}
                      dashboardId={curDashboardId}
                      dashboardName={curDashboardName}
                      isEditMode={isEditMode}
                    ></GadgetColumn>
                    <GadgetColumn
                      column={5}
                      section={2}
                      dashboardId={curDashboardId}
                      dashboardName={curDashboardName}
                      isEditMode={isEditMode}
                    ></GadgetColumn>
                  </div>
                )}
              </div>
              <GadgetColumn
                column={3}
                section={3}
                dashboardId={curDashboardId}
                dashboardName={curDashboardName}
                isEditMode={isEditMode}
              ></GadgetColumn>
            </div>
          </>
        )}
      </div>
    </>
  );
}
