import DashboardControl from '../../header/control';
import { ManageDashboardHeader } from './manage-dashboard-header';

interface Props {
  isEditMode: boolean;
  setEditMode?: React.Dispatch<React.SetStateAction<boolean>>;
}

export function ManageHeader({ isEditMode, setEditMode }: Props) {
  if (!setEditMode) return;
  return isEditMode ? (
    <DashboardControl dashboardType="BUSINESS" setEditMode={setEditMode} />
  ) : (
    <ManageDashboardHeader />
  );
}
