import {
  CheckBox,
  Props as CheckBoxProps,
} from '../../../../../shared/ui/check-box/check-box';
import { clsx } from 'clsx';
import styles from '../styles.module.css';

interface Props extends CheckBoxProps {}

export function GadgetCheckBox({ className, ...props }: Props) {
  return <CheckBox className={clsx(styles.CheckBox, className)} {...props} />;
}

export default GadgetCheckBox;
