import { IconButton } from '../../../../../shared/ui/button';
import {
  NextIndicatorIcon,
  PreviousIndicatorIcon,
} from '@dop-ui/icons/react/dop/16';
import { clsx } from 'clsx';
import { Dispatch, SetStateAction } from 'react';

import styles from '../styles.module.css';

export interface Props {
  currentPage: number;
  lastPage: number;
  setPage: Dispatch<SetStateAction<number>>;
  className?: string;
}

export function Paginator({
  currentPage,
  lastPage,
  setPage,
  className,
}: Props) {
  const isFirst = currentPage <= 0;
  const isLast = currentPage >= lastPage - 1;
  const handleOnClickPrevPage = () => {
    if (currentPage === 0) return;
    setPage((page) => page - 1);
  };

  const handleOnClickNextPage = () => {
    if (currentPage === lastPage - 1) return;
    setPage((page) => page + 1);
  };
  return (
    <div className={clsx(styles.Paginator, className)}>
      <IconButton
        size="xs"
        onClick={() => handleOnClickPrevPage()}
        disabled={isFirst}
        icon={PreviousIndicatorIcon}
      />

      <IconButton
        size="xs"
        disabled={isLast}
        onClick={() => handleOnClickNextPage()}
        icon={NextIndicatorIcon}
      />
    </div>
  );
}

export default Paginator;
