import { useDashboardActionsContext } from '../../../context';
import { GadgetApiParams } from '../../../types';
import { ManageGadgetContainer } from './manage-gadget-container';
import * as DnDList from '../../../../../shared/ui/dnd/list';
import { ManageGadgetDropArea } from './manage-gadget-drop-area';
import { ManageFixedGadget } from './manage-fixed-gadget';

export interface Props {
  column: number;
  section: number;
  isEditMode: boolean;
  dashboardId: number;
}

const DropBoxIndicatorComp = (
  <div
    style={{
      position: 'relative',
      zIndex: 10,
    }}
    className="h-[300px] !border-dashed !border-4 border-gray-400 rounded-2xl bg-gray-200 mb-4"
  />
);

export function ManageGadgetColumn({
  column,
  section,
  isEditMode,
  dashboardId,
}: Props) {
  const context = useDashboardActionsContext();
  const { getGadgetsByColumnSeq } = context;
  const gadgets = getGadgetsByColumnSeq(column - 1) || [];

  return (
    <div
      className={`go-gadget-column gadget-col-${column} gadget_section${section} ui-sortable`}
    >
      {section === 1 && <ManageFixedGadget content={'공지 영역'} />}
      {section === 2 && <ManageFixedGadget content={'광고 영역'} />}

      {gadgets.length === 0 && isEditMode && (
        <DnDList.Item
          key={`gadget-drop-area-${column}`}
          item={{
            uuid: `gadget-drop-area-${column - 1}`,
            region: column - 1,
            sequence: 0,
          }}
          allowedEdges={['top', 'bottom']}
          useDropIndicator={true}
          DropBoxIndicatorComp={DropBoxIndicatorComp}
        >
          <ManageGadgetDropArea />
        </DnDList.Item>
      )}

      {gadgets
        .sort((a, b) => a.sequence - b.sequence)
        .map((gadget: GadgetApiParams, index) => (
          <DnDList.Item
            key={gadget.uuid}
            item={gadget}
            allowedEdges={['top', 'bottom']}
            useDropIndicator={true}
            DropBoxIndicatorComp={DropBoxIndicatorComp}
          >
            <div className="gadget_layout_wrapper">
              <ManageGadgetContainer
                key={`gadget-${gadget.uuid}`}
                gadget={gadget}
                isEditMode={isEditMode}
                seq={index}
                dashboardId={dashboardId}
              />
            </div>
          </DnDList.Item>
        ))}
    </div>
  );
}
