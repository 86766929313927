import { InputHTMLAttributes } from 'react';
import { clsx } from 'clsx';
import styles from '../styles.module.css';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
}
export function GadgetInput({ className, ...props }: Props) {
  return <input className={clsx(styles.Input, className)} {...props} />;
}

export default GadgetInput;
