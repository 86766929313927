import { clsx } from 'clsx';
import styles from './styles.module.css';
import { GadgetEmpty } from '../empty-message';
import { BoardInfo, BoardViewType } from '../../types';
import { NormalTemplate } from './normal-template';
import { ThumbnailTemplate } from './thumbnail-template';
import { AlbumTemplate } from './album-template';
import { useTranslation } from '../../../../../../shared/lib/i18n/client/use-translation';
import React from 'react';

interface Props {
  data: BoardInfo[];
  viewType: BoardViewType;
  linkUrl: string;
  emptyTxt: string;
  community?: boolean;
  className?: string;
  appCode?: string;
}

export function GadgetBoardList({
  data,
  viewType,
  linkUrl,
  emptyTxt,
  community = false,
  appCode = 'dop-employee-board',
  className,
}: Props) {
  const { t } = useTranslation('component');

  const getUrlInfo = (linkUrl: string, data: BoardInfo, linkType: string) => {
    let url = linkUrl;
    if (community) {
      url += `/${data.boardMasterOwner.userId}/board`;
    }

    if (linkType === 'board') {
      url += `/${data.boardId}`;
    } else if (linkType === 'post') {
      if (data.type === 'CLASSIC') {
        url += `/${data.boardId}/post/${data.id}`;
      } else if (data.type === 'STREAM') {
        url += `/${data.boardId}/post/${data.id}/stream`;
      } else {
        url += `/${data.boardId}`;
      }
    }

    return url;
  };

  const renderTemplate = (data: BoardInfo) => {
    if (data.hiddenPost) {
      return (
        <p key={data.id} className={styles.BoardTitle}>
          <span className="ic_side ic_private"></span>
          {data.hiddenTitle ? t('gadget.board.notauth') : data.title}
        </p>
      );
    }

    const postUrl = getUrlInfo(linkUrl, data, 'post');
    const boardUrl = getUrlInfo(linkUrl, data, 'board');

    return (
      <React.Fragment key={data.id}>
        {viewType === 'normal' && (
          <NormalTemplate data={data} postUrl={postUrl} boardUrl={boardUrl} />
        )}
        {viewType === 'thumbnail' && (
          <ThumbnailTemplate
            data={data}
            postUrl={postUrl}
            boardUrl={boardUrl}
            appCode={appCode}
          />
        )}
        {viewType === 'album' && (
          <AlbumTemplate data={data} postUrl={postUrl} appCode={appCode} />
        )}
      </React.Fragment>
    );
  };

  return (
    <ul className={clsx(className, styles.BoardWrapper)}>
      {data.length > 0 ? (
        data.map((data) => renderTemplate(data))
      ) : (
        <GadgetEmpty message={emptyTxt} />
      )}
    </ul>
  );
}

export default GadgetBoardList;
