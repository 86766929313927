import { clsx } from 'clsx';
import { DashboardProps } from '../../types';
import { CogIcon } from '@dop-ui/icons/react/dop/24';
import { useDashboardContext } from '../../context';
import { IconButton } from '../../../../shared/ui/button';

export interface Props {
  name: string;
  dashboard: DashboardProps;
  isTempDashboard?: boolean;
  onClick?: () => void;
}

export default function Tab({
  name,
  dashboard,
  onClick = () => undefined,
}: Props) {
  const { actions } = useDashboardContext();
  const { toggleDashboard, setCurrentDashboard } = actions;
  const { dashboardId, updatable, activated, deletable } = dashboard;

  const handleDashboardClick = () => {
    toggleDashboard(dashboardId);
    setCurrentDashboard(dashboard);
  };

  return (
    <li
      className={clsx('flex items-center justify-center gap-x-1')}
      onClick={() => dashboard && void handleDashboardClick()}
      aria-hidden="true"
    >
      <button
        className={clsx(
          'max-w-[150px] text-ellipsis whitespace-nowrap overflow-hidden text-[18px]',
          {
            'text-[#363636] font-semibold': activated,
            'text-[#D8D8D8]': !activated,
          },
        )}
      >
        {name}
      </button>
      {(updatable || deletable) && activated && (
        <IconButton className="!p-1" icon={CogIcon} onClick={() => onClick()} />
      )}
    </li>
  );
}
