interface Props {
  content: string;
  hidden?: boolean;
}

export function ManageFixedGadget({ content, hidden = false }: Props) {
  return (
    <>
      {hidden ? (
        <></>
      ) : (
        <div className="min-h-[100px] bg-gray-300 mb-6">{content}</div>
      )}
    </>
  );
}
