import { clsx } from 'clsx';
import styles from '../styles.module.css';

interface Props {
  title?: string;
  className?: string;
}
export function ConfigField({ title, className }: Props) {
  return <p className={clsx(styles.ConfigTitle, className)}>{title}</p>;
}

export default ConfigField;
