import { Button } from '../../../../../shared/ui/button';
import { useDashboardActionsContext } from '../../../context';
import HeaderContainer from '../../header/container';
import { useTranslation } from '../../../../../shared/lib/i18n/client';

export function ChatDashboardHeader() {
  const { t } = useTranslation();
  const { setEditMode } = useDashboardActionsContext();
  return (
    <HeaderContainer>
      <div className="flex flex-row justify-between w-full">
        <h1 className="text-2xl">{t('chat.home.today.title')}</h1>
        <Button
          colorset="level2"
          size="md"
          variant="solid"
          shape="round"
          onClick={() => setEditMode(true)}
        >
          {t('chat.home.today.setting')}
        </Button>
      </div>
    </HeaderContainer>
  );
}
