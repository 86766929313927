import { clsx } from 'clsx';
import styles from '../styles.module.css';

interface RadioOption {
  value: string;
  label: string;
}

interface GadgetRadioProps {
  options: RadioOption[];
  selectedValue: string;
  onChange: (value: string) => void;
  className?: string;
}

export function GadgetRadio({
  options,
  selectedValue,
  onChange,
  className,
}: GadgetRadioProps) {
  return (
    <div className={clsx(styles.Radio, className)}>
      {options.map((option) => (
        <div key={option.value}>
          <input
            className={styles.RadioInput}
            type="radio"
            id={option.value}
            name="shareTarget"
            value={option.value}
            checked={selectedValue === option.value}
            onChange={() => onChange(option.value)}
          />
          <label htmlFor={option.value}>{option.label}</label>
        </div>
      ))}
    </div>
  );
}

export default GadgetRadio;
