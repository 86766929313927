import { useState } from 'react';
import ControlToolbar from './toolbar';
import GadgetSpecList from './gadget-spec-list';
import { DashboardType } from '../../../types';
import ChatControlToolbar from '../../chat/header/control/chat-toolbar';
import { ManageControlToolbar } from '../../manage/header/control/manage-control-toolbar';
import ManageGadgetSpecList from '../../manage/header/control/manage-gadget-spec-list';

interface Props {
  dashboardType?: DashboardType;
  setEditMode?: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function DashboardControl({
  dashboardType = 'EMPLOYEE',
  setEditMode,
}: Props) {
  const [isVisibleGadgetSpecs, setVisibleGadgetSpecs] = useState<boolean>(true);

  return (
    <>
      {dashboardType === 'MESSENGER' ? (
        <ChatControlToolbar />
      ) : dashboardType === 'BUSINESS' ? (
        <ManageControlToolbar
          setEditMode={setEditMode}
          toggleGadgetSpecs={(bool) => setVisibleGadgetSpecs(bool)}
          isVisibleGadgetSpecs={isVisibleGadgetSpecs}
        />
      ) : (
        <ControlToolbar
          toggleGadgetSpecs={(bool) => setVisibleGadgetSpecs(bool)}
          isVisibleGadgetSpecs={isVisibleGadgetSpecs}
        />
      )}

      {isVisibleGadgetSpecs &&
        dashboardType !== 'MESSENGER' &&
        (dashboardType === 'EMPLOYEE' ? (
          <GadgetSpecList />
        ) : (
          <ManageGadgetSpecList />
        ))}
    </>
  );
}
