import * as React from 'react';
import Image from 'next/image';
import { generateUUID } from '../../../../apis';
import { GadgetSpecProps } from '../../../../types';
import { clsx } from 'clsx';
import dummyThumbnail from './dummy-thumb.png';
import { useDashboardActionsContext } from '../../../../context';
import * as DnDList from '../../../../../../shared/ui/dnd/list';

export const getGadgetDefaultData = () => {
  return {
    groupNo: 0,
    region: 0,
    sequence: 0,
    option: '{"refresh":-1}',
  };
};

export interface Props {
  dashboardId: number;
  spec: GadgetSpecProps;
  className?: string;
}

export default function ManageGadgetSpecItem({ spec, className }: Props) {
  const context = useDashboardActionsContext();
  const { getGadgetsByColumnSeq, reorderGadgets, getGadgetThumbnail } = context;
  const imageRef = React.useRef(null);

  const handleGadgetSpecClick = () => {
    const gadgets = getGadgetsByColumnSeq(0);
    if (!gadgets) return;

    const sequence = gadgets.length;
    const gadget = {
      ...getGadgetDefaultData(),
      ...spec,
      sequence,
      region: 0,
      uuid: generateUUID(),
    };
    reorderGadgets(gadget, 0, 0);
  };

  const thumbnail =
    (getGadgetThumbnail && getGadgetThumbnail(spec.name)) || dummyThumbnail;

  // manage-portal에서 thumbnail 경로가 //로 시작되어 내려옴
  if (typeof thumbnail === 'object' && thumbnail.src.startsWith('//')) {
    thumbnail.src = thumbnail.src.replace('//', '/');
  } else if (typeof thumbnail === 'string' && thumbnail.startsWith('//')) {
    thumbnail.replace('//', '/');
  }

  const getGadget = () => {
    return {
      ...getGadgetDefaultData(),
      ...spec,
      sequence: 0,
      region: 0,
      uuid: `gadget-thumbnail-${spec.gadgetTemplateId}`,
      data: spec,
    };
  };

  return (
    <DnDList.Item
      className="shrink-0 group flex flex-col items-center gap-y-2 hover:cursor-pointer"
      key={getGadget().uuid}
      item={getGadget()}
      dragHandleRef={imageRef}
      droppable={false}
      useDropIndicator={false}
    >
      <li
        className={clsx(className)}
        onClick={() => void handleGadgetSpecClick()}
        aria-hidden="true"
      >
        <Image
          src={thumbnail}
          ref={imageRef}
          className="h-[100px] w-[auto] group-hover:border-2 group-hover:border-[#25cad6] group-hover:border-solid group-hover:rounded group-hover:shadow-lg group-hover:scale-95 group-hover:transition-transform"
          alt={''}
          width={'100'}
          height={'100'}
        />
        <p className="text-center">{spec.displayName}</p>
      </li>
    </DnDList.Item>
  );
}
