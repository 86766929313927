import {
  DashboardProps,
  DashboardType,
  GadgetApiParams,
  GadgetSpecProps,
} from '../types';

interface CreateDashboardParams
  extends Pick<DashboardProps, 'name' | 'layout'> {
  gadgetList: Array<GadgetApiParams>;
}

interface UpdateDashboardParams extends CreateDashboardParams {
  dashboardId: number;
}

interface DashboardsResponseBody {
  data: {
    dashboardList: Array<DashboardProps>;
    extendable: boolean;
    firstCreate: boolean;
  };
}

interface DashboardResponseBody {
  data: DashboardProps;
}

interface GadgetSpecsResponseBody {
  data: { gadgetTemplateList: Array<GadgetSpecProps> };
}

interface GadgetsResponseBody {
  data: { gadgetList: Array<GadgetApiParams> };
}

export async function readDashboards(dashboardType: DashboardType) {
  const resp = await fetch(`/api/portal/dashboards/${dashboardType}`, {});
  if (!resp.ok) {
    console.log(resp);
    throw new Error('Dashboard > readDashboards 오류 발생.');
  }
  const { data }: DashboardsResponseBody =
    (await resp.json()) as DashboardsResponseBody;
  return data;
}

export async function createDashboard(
  params: CreateDashboardParams,
  dashboardType: DashboardType,
) {
  const resp = await fetch(`/api/portal/dashboards/${dashboardType}`, {
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
    method: 'POST',
  });
  if (!resp.ok) {
    console.log(resp);
    throw new Error('Dashboard > createDashboard 오류 발생.');
  }
  const { data }: DashboardResponseBody =
    (await resp.json()) as DashboardResponseBody;

  return data;
}

export async function updateDashboard(params: UpdateDashboardParams) {
  const { dashboardId, ...reqParams } = params;
  const resp = await fetch(`/api/portal/dashboards/${dashboardId}`, {
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(reqParams),
    method: 'PUT',
  });
  if (!resp.ok) {
    console.log(resp);
    throw new Error('Dashboard > updateDashboard 오류 발생.');
  }
  const { data }: DashboardResponseBody =
    (await resp.json()) as DashboardResponseBody;
  return data;
}

export async function deleteDashboard(dashboardId: number) {
  const resp = await fetch(`/api/portal/dashboards/${dashboardId}`, {
    method: 'DELETE',
  });
  if (!resp.ok) {
    console.log(resp);
    throw new Error('Dashboard > deleteDashboard 오류 발생.');
  }
  const { data }: DashboardsResponseBody =
    (await resp.json()) as DashboardsResponseBody;
  return data;
}

export async function readGadgetSpecs(dashboardType: DashboardType) {
  const resp = await fetch(
    `/api/portal/dashboards/${dashboardType}/gadgets/templates`,
    {},
  );
  if (!resp.ok) {
    console.log(resp);
    throw new Error('Dashboard > readGadgetSpecs 오류 발생.');
  }
  const { data }: GadgetSpecsResponseBody =
    (await resp.json()) as GadgetSpecsResponseBody;
  return data;
}

export async function readGadgets(dashboardId: number) {
  const resp = await fetch(`/api/portal/dashboards/${dashboardId}/gadgets`);
  if (!resp.ok) {
    console.log(resp);
    throw new Error('Dashboard > readGadgets 오류 발생.');
  }
  const { data }: GadgetsResponseBody =
    (await resp.json()) as GadgetsResponseBody;
  data.gadgetList.forEach((gadget) => {
    gadget.uuid = generateUUID();
  });
  return data;
}

// TODO. 유틸처리
export const generateUUID = () => {
  let d = new Date().getTime();
  let d2 =
    (typeof performance !== 'undefined' &&
      performance.now &&
      performance.now() * 1000) ||
    0;
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = Math.random() * 16;
    if (d > 0) {
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
};
