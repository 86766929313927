import { BoardInfo } from '../../types';
import styles from './styles.module.css';
import { Avatar } from '../../../../../../shared/ui/avatar';
import { clsx } from 'clsx';
import { formatDate } from '../../../../../../shared/lib/date';

interface Props {
  data: BoardInfo;
  postUrl: string;
  boardUrl: string;
  appCode: string;
}

export function ThumbnailTemplate({ data, postUrl, boardUrl, appCode }: Props) {
  return (
    <li className={styles.BoardItem}>
      <div className={styles.BoardThumbnail}>
        <div className={styles.BoardThumbnailTxt}>
          <p className={styles.BoardTitle}>
            {data.title ? (
              <a
                className={clsx({
                  [styles.BoardStickable as string]: data.stickable,
                })}
                href={postUrl}
              >
                {data.title}
                {data.commentsCount > 0 && `[${data.commentsCount}]`}
              </a>
            ) : (
              <a
                className={clsx({
                  [styles.BoardStickable as string]: data.stickable,
                })}
                href={boardUrl}
              >
                {data.summary}
                {data.commentsCount > 0 && `[${data.commentsCount}]`}
              </a>
            )}
          </p>
          <span className={styles.BoardInfo}>
            <Avatar
              size={14}
              alt={data.writer?.name}
              src={data.writer?.photoPath}
            />
            <span>{`${data.writer?.name} ${data.writer?.positionName}`}</span>
            <span>{` · ${formatDate({
              date: data.createdAt,
              format: 'YYYY-MM-DD hh:mm',
            })}`}</span>
            <span>
              <span>{` · `}</span>
              <a href={boardUrl}>{data.boardName}</a>
            </span>
          </span>
        </div>
        {data.repImage?.path && (
          <div className={styles.BoardThumbnailImg}>
            <img
              src={`/api/thumb/attach/mid/${appCode}/${data.repImage.path}`}
              alt=""
            />
          </div>
        )}
      </div>
    </li>
  );
}

export default ThumbnailTemplate;
