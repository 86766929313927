import { useEffect } from 'react';
import { useDebounce } from './use-debounce';

export interface Options {
  debounced?: boolean;
  debouncedTimeMs?: number;
}

export function useWindowResizeObserver(
  handler: () => void,
  { debounced = true, debouncedTimeMs = 300 }: Options,
) {
  const debouncedResizeObserver = useDebounce(handler, debouncedTimeMs);

  useEffect(() => {
    const listener = debounced ? debouncedResizeObserver : handler;
    window.addEventListener('resize', listener);

    return () => {
      window.removeEventListener('resize', listener);
    };
  }, [handler, debounced, debouncedTimeMs]);
}

export default useWindowResizeObserver;
